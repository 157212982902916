<template>

  <b-container>
    <b-overlay
      :show="show_loading"
      rounded="lg"
      variant="white"
      :opacity="1"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong>Carregando os dados, Aguarde...</strong>
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <transition name="zoom-fade">
        <b-card title="Detalhes">
          <b-form-group label="Nome do Perfil:">
            <span class="font-weight-bold">
              {{ profile_details.profile_name }}</span>
          </b-form-group>
          <b-form-group label="Menu Relacionado">
            <b-list-group
              v-for="item in profile_list"
              :key="item.id"
              flush
            >
              <b-list-group-item>{{ item.namePermission }}</b-list-group-item>
            </b-list-group>
          </b-form-group>
          <b-form-group label="Usuários Relacionados:">
            <b-list-group
              v-for="item in backoffice_users"
              :key="item.id"
              flush
            >
              <b-list-group-item>{{ item.name_user }}</b-list-group-item>
            </b-list-group>
          </b-form-group>
          <b-row>
            <b-col
              md="6"
              sm="12"
              class="mt-1"
            >
              <b-button
                variant="primary"
                block
                :to="{ name: 'edit-profile', params: { id: id} }"
              >
                Editar
              </b-button>
            </b-col>
            <b-col
              md="6"
              sm="12"
              class="mt-1"
            >
              <b-button
                variant="primary"
                block
                :to="{ name:'usuarios' }"
              >Voltar</b-button>
            </b-col>
          </b-row>
        </b-card>
      </transition>
    </b-overlay>
  </b-container>
</template>
<script>
import {
  BContainer,
  BRow,
  BCard,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BIcon,
  BFormGroup,
  BSpinner,
  BButton,
  BCol,
} from 'bootstrap-vue'
import { getProfile } from '@/service/profile/profile'

export default {
  components: {
    BContainer,
    BRow,
    BCard,
    BListGroup,
    BListGroupItem,
    BOverlay,
    BIcon,
    BFormGroup,
    BSpinner,
    BButton,
    BCol,
  },
  data() {
    return {
      show_loading: false,
      id: this.$route.params.id,
      profile_details: [],
      backoffice_users: [],
      profile_list: [],
    }
  },

  async created() {
    this.show_loading = true
    await this.getProfile()
  },
  methods: {
    async getProfile() {
      const response = await getProfile(this.id)
      const { status, data } = response

      if (status !== 200) {
        this.$router.push('error-404')
        return
      }
      this.profile_details = data

      const { backoffice_users, permissions } = data

      const backofficeUsers = backoffice_users.map(backofficeUser => {
        const { id, name } = backofficeUser.user

        return {
          id,
          name_user: name,
        }
      })
      const userPermissions = permissions.map(permission => ({
        id: permission.permission_id,
        namePermission: permission.permission,
      }))

      this.profile_list = userPermissions
      this.backoffice_users = backofficeUsers
      this.show_loading = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/pages/new-client.scss";

</style>
